/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__container {
  max-width: 550px;
  margin: auto;
  height: 400px;

}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 25%;
  padding-right: 25%;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
  background-color: burlywood;
}



.carousel-item {
  width: 200px; /* Set your desired image width */
  height: 200px; /* Set your desired image height */
  margin: 0 5px;
  filter: blur(5px); /* Apply blur to all items by default */
  transition: filter 0.3s ease-in-out;
}

.carousel-item.active {
  filter: none; /* Remove blur for the active (current) item */
}

.carousel__container {
  max-width: 550px;
  margin: auto;
  height: 400px;

}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 25%;
  padding-right: 25%;
}

.carousel__inner-slide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
  background-color: burlywood;
}



.carousel-item {
  width: 200px; /* Set your desired image width */
  height: 200px; /* Set your desired image height */
  margin: 0 5px;
  filter: blur(5px); /* Apply blur to all items by default */
  transition: filter 0.3s ease-in-out;
}

.carousel-item.active {
  filter: none; /* Remove blur for the active (current) item */
}



.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider (toggle switch) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

/* The circle inside the slider */
.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

/* When checkbox is checked */
input:checked + .slider {
  background-color: #2261A2;
}

input:checked + .slider::before {
  transform: translateX(26px);
}
/* background image */

.bxilogoimageclass {
    background-image: url("https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BXIAgreementLogo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    max-width: 120px;
    height: 100%;
    max-height: 120px;
  }
  
  .bxiarrowimageclass {
    background-image: url("https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/VectorArrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    max-width: 120px;
    height: 100%;
    max-height: 120px;
    
  }

  
  .maindiv {
    height: 450px;
    width: 350px;
  }
  /* @media only screen and (max-width: 600px) {
    .App {
      bottom: 60px;
      right: 10px;
    }
    .maindiv {
      height: 390px;
      width: 280px;
    }
  } */
  
  .App-link {
    color: #063970;
  }
  
  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    a:nth-of-type(2) .logo {
      animation: logo-spin infinite 20s linear;
    }
  }
  
  .card {
    padding: 5em;
  }
  
  #chat-circle {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #cdd8ec;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: white;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .cs-button.cs-button--attachment {
    display: none !important;
  }
  
  .cs-message-input.content-editor-wrapper {
    margin-left: 10px;
    background-color: #cdd8ec !important;
  }
  
  @media only screen and (max-width: 600px) {
    #chat-circle {
      bottom: 20px;
      right: 25px;
      width: 15px;
      height: 15px;
    }
  }
  
  .cs-message-list {
    margin-top: "5px" !important;
    margin-bottom: "10px" !important;
  
    /* background-color: red !important; */
  }
  
  .cs-message__content {
    background-color: #f4f7f9 !important ;
    font-weight: 400 !important;
  }
  
  .cs-message--outgoing .cs-message__content {
    background-color: #cdd8ec !important;
    border-radius: 0.7em 0.7em 0.7em 0.7em !important;
  }
  
  .cs-message-input__content-editor-wrapper {
    background-color: #f4f7f9 !important ;
  }
  
  .cs-button.cs-button--send {
    color: #cdd8ec !important;
  }
  
  .cs-message-input__content-editor {
    background-color: #f4f7f9 !important ;
  }
  
  .ps__thumb-y {
    background-color: #cdd8ec !important;
  }
  
  .cs-typing-indicator__dot {
    background-color: #334588 !important;
  }
  
  .cs-typing-indicator__text {
    color: #334588 !important;
  }
  
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@500;700&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500;600&family=Outfit:wght@500;700&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@600&family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
/* :root {

} */

.App {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

*:focus {
  outline: none;
}

body {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.PrivateTabIndicator-root-1.PrivateTabIndicator-colorSecondary-3.MuiTabs-indicator {
  background-color: #3860de;
}
.PrivateTabIndicator-root-5.PrivateTabIndicator-colorSecondary-7.MuiTabs-indicator {
  background-color: #3860de;
}
.MuiGrid-root.MuiGrid-container.css-1veizgn-MuiGrid-root {
  height: 100vh;
}
.MuiTab-wrapper {
  font-size: 14px;
  color: #bcc5d6;
}
.css-1pfbzjm-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  left: 0px !important;
}

.css-1jgibdo-MuiPaper-root-MuiAccordion-root:before {
  height: 0px !important;
  left: 0px !important;
}
.css-1jgibdo-MuiPaper-root-MuiAccordion-root:after {
  height: 0px !important;
  left: 0px !important;
}
.MuiBox-root.css-iuxnrt {
  margin-top: 5px;
}
@media (max-width: 768px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 768px) {
  .MuiBox-root.css-1b2013j {
    width: 33px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 52%;
  }
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

/* ::-webkit-scrollbar {
  display: none;
} */
::-webkit-scrollbar {
  width: 05px;
}

::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(59, 52, 134, 0.38);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
.triple-spinner {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #445fd2;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #445fd2;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #445fd2;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

/* tripper spinner two */

.triple-spinner-two {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #2261a2;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.triple-spinner-two::before,
.triple-spinner-two::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.triple-spinner-two::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #2261a2;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner-two::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #2261a2;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

::-webkit-input-placeholder {
  margin-left: 5px;
  font-size: 15px;
}

.timeline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeline-event {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}

.event-date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-description {
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 10px;
  display: none;
}

.timeline-event:hover .event-description {
  display: block;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media print {
  @page {
    size: portrait;
    
  }
}


@media print {
  body {
    zoom: 85%; /* Scale down everything for better fit */
  }

  .print-table {
    width: 100% !important;
    font-size: 12px !important;
  }

  .print-table-cell {
    width: 5px !important;
    max-width: 5px !important;
    font-size: 10px !important;
    padding: 2px !important;
  }

  .print-text {
    font-size: 12px !important;
  }

  .no-print {
    display: none !important; /* Hide unnecessary elements */
  }
}


